import { ObjectValuesType } from 'addiction-components';

export const PLACEHOLDER_SECTION = {
  PATIENT_NAME: '$$_NAME_$$',
  PRACTICE_DATE: '$$_DOC_DATE_$$',
} as const;

export type PlaceholderSection = ObjectValuesType<typeof PLACEHOLDER_SECTION>;

export interface PlaceholderSectionElementMap {
  placeholder: string;
  value: string;
}
