import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Practice } from '../models/practice';
import { ROUTES_NAME } from '../models/routes-name';
import { isPractice } from '@consenso-informato/models';

export type PrintableEntity = Practice;

/**
 * Servizio in root utilizzato per gestire il print dei documenti
 * Internamente naviga verso le rotte del print e chiama la window.print
 */
@Injectable({
  providedIn: 'root',
})
export class PrintService {
  private router = inject(Router);

  print(element: PrintableEntity) {
    if (isPractice(element)) {
      this.printPractice(element.uuid);
    }
  }

  private printPractice(practice: string) {
    this.router.navigate(
      [
        '/',
        {
          outlets: {
            print: [ROUTES_NAME.PRINT, ROUTES_NAME.PRACTICE, practice],
          },
        },
      ]
    );
  }

  /**
   * Metodo chiamato dai componenti layout per chiamare il print
   */
  onDataReady() {
    window.print();
    this.router.navigate([{ outlets: { print: null } }]);
  }
}
